import { IconAdjustments } from '@/designSystem/Icon/Internal/Icons/IconAdjustments';
import { IconArrowDownward } from '@/designSystem/Icon/Internal/Icons/IconArrowDownward';
import { IconArrowLeft } from '@/designSystem/Icon/Internal/Icons/IconArrowLeft';
import { IconBadgeCheck } from '@/designSystem/Icon/Internal/Icons/IconBadgeCheck';
import { IconBarChart } from '@/designSystem/Icon/Internal/Icons/IconBarChart';
import { IconBell } from '@/designSystem/Icon/Internal/Icons/IconBell';
import { IconBookOpen } from '@/designSystem/Icon/Internal/Icons/IconBookOpen';
import { IconCalendar } from '@/designSystem/Icon/Internal/Icons/IconCalendar';
import { IconCalendarOutlined } from '@/designSystem/Icon/Internal/Icons/IconCalendarOutlined';
import { IconCancel } from '@/designSystem/Icon/Internal/Icons/IconCancel';
import { IconCertified } from '@/designSystem/Icon/Internal/Icons/IconCertified';
import { IconCertifiedOutlined } from '@/designSystem/Icon/Internal/Icons/IconCertifiedOutlined';
import { IconChartSquareBar } from '@/designSystem/Icon/Internal/Icons/IconChartSquareBar';
import { IconChat } from '@/designSystem/Icon/Internal/Icons/IconChat';
import { IconChatAlt } from '@/designSystem/Icon/Internal/Icons/IconChatAlt';
import { IconChatAlt2Outlined } from '@/designSystem/Icon/Internal/Icons/IconChatAlt2Outlined';
import { IconCheck } from '@/designSystem/Icon/Internal/Icons/IconCheck';
import { IconCheckCircle } from '@/designSystem/Icon/Internal/Icons/IconCheckCircle';
import { IconCheckCircleOutlined } from '@/designSystem/Icon/Internal/Icons/IconCheckCircleOutlined';
import { IconChevronDoubleUp } from '@/designSystem/Icon/Internal/Icons/IconChevronDoubleUp';
import { IconChevronDown } from '@/designSystem/Icon/Internal/Icons/IconChevronDown';
import { IconChevronLeft } from '@/designSystem/Icon/Internal/Icons/IconChevronLeft';
import { IconChevronRight } from '@/designSystem/Icon/Internal/Icons/IconChevronRight';
import { IconChevronUp } from '@/designSystem/Icon/Internal/Icons/IconChevronUp';
import { IconCircle } from '@/designSystem/Icon/Internal/Icons/IconCircle';
import { IconCircleDotted } from '@/designSystem/Icon/Internal/Icons/IconCircleDotted';
import { IconClock } from '@/designSystem/Icon/Internal/Icons/IconClock';
import { IconCollection } from '@/designSystem/Icon/Internal/Icons/IconCollection';
import { IconCopy } from '@/designSystem/Icon/Internal/Icons/IconCopy';
import { IconCreditCard } from '@/designSystem/Icon/Internal/Icons/IconCreditCard';
import { IconDanger } from '@/designSystem/Icon/Internal/Icons/IconDanger';
import { IconDatabase } from '@/designSystem/Icon/Internal/Icons/IconDatabase';
import { IconDesktopComputer } from '@/designSystem/Icon/Internal/Icons/IconDesktopComputer';
import { IconDocument } from '@/designSystem/Icon/Internal/Icons/IconDocument';
import { IconDotsHorizontal } from '@/designSystem/Icon/Internal/Icons/IconDotsHorizontal';
import { IconDownload } from '@/designSystem/Icon/Internal/Icons/IconDownload';
import { IconEmojiHappy } from '@/designSystem/Icon/Internal/Icons/IconEmojiHappy';
import { IconEuro } from '@/designSystem/Icon/Internal/Icons/IconEuro';
import { IconEye } from '@/designSystem/Icon/Internal/Icons/IconEye';
import { IconEyeOff } from '@/designSystem/Icon/Internal/Icons/IconEyeOff';
import { IconFacebook } from '@/designSystem/Icon/Internal/Icons/IconFacebook';
import { IconFlag } from '@/designSystem/Icon/Internal/Icons/IconFlag';
import { IconFolder } from '@/designSystem/Icon/Internal/Icons/IconFolder';
import { IconGlobe } from '@/designSystem/Icon/Internal/Icons/IconGlobe';
import { IconGoogle } from '@/designSystem/Icon/Internal/Icons/IconGoogle';
import { IconInfo } from '@/designSystem/Icon/Internal/Icons/IconInfo';
import { IconInstagram } from '@/designSystem/Icon/Internal/Icons/IconInstagram';
import { IconLevel } from '@/designSystem/Icon/Internal/Icons/IconLevel';
import { IconLightBulb } from '@/designSystem/Icon/Internal/Icons/IconLightBulb';
import { IconLightningBolt } from '@/designSystem/Icon/Internal/Icons/IconLightningBolt';
import { IconLink } from '@/designSystem/Icon/Internal/Icons/IconLink';
import { IconLive } from '@/designSystem/Icon/Internal/Icons/IconLive';
import { IconLocation } from '@/designSystem/Icon/Internal/Icons/IconLocation';
import { IconLockClosed } from '@/designSystem/Icon/Internal/Icons/IconLockClosed';
import { IconMail } from '@/designSystem/Icon/Internal/Icons/IconMail';
import { IconMarianne } from '@/designSystem/Icon/Internal/Icons/IconMarianne';
import { IconMasterCard } from '@/designSystem/Icon/Internal/Icons/IconMasterCard';
import { IconMessage } from '@/designSystem/Icon/Internal/Icons/IconMessage';
import { IconMinus } from '@/designSystem/Icon/Internal/Icons/IconMinus';
import { IconMinusCircle } from '@/designSystem/Icon/Internal/Icons/IconMinusCircle';
import { IconMovie } from '@/designSystem/Icon/Internal/Icons/IconMovie';
import { IconNewspaper } from '@/designSystem/Icon/Internal/Icons/IconNewspaper';
import { IconOnline } from '@/designSystem/Icon/Internal/Icons/IconOnline';
import { IconPanel } from '@/designSystem/Icon/Internal/Icons/IconPanel';
import { IconPaperAirplane } from '@/designSystem/Icon/Internal/Icons/IconPaperAirplane';
import { IconPaperClip } from '@/designSystem/Icon/Internal/Icons/IconPaperClip';
import { IconPencil } from '@/designSystem/Icon/Internal/Icons/IconPencil';
import { IconPhone } from '@/designSystem/Icon/Internal/Icons/IconPhone';
import { IconPlay } from '@/designSystem/Icon/Internal/Icons/IconPlay';
import { IconPlus } from '@/designSystem/Icon/Internal/Icons/IconPlus';
import { IconPlusCircle } from '@/designSystem/Icon/Internal/Icons/IconPlusCircle';
import { IconPresentationChartLine } from '@/designSystem/Icon/Internal/Icons/IconPresentationChartLine';
import { IconProfile } from '@/designSystem/Icon/Internal/Icons/IconProfile';
import { IconReceiptTax } from '@/designSystem/Icon/Internal/Icons/IconReceiptTax';
import { IconRefresh } from '@/designSystem/Icon/Internal/Icons/IconRefresh';
import { IconReply } from '@/designSystem/Icon/Internal/Icons/IconReply';
import { IconSetting } from '@/designSystem/Icon/Internal/Icons/IconSetting';
import { IconSherpaGreen } from '@/designSystem/Icon/Internal/Icons/IconSherpaGreen';
import { IconSherpaWhite } from '@/designSystem/Icon/Internal/Icons/IconSherpaWhite';
import { IconShieldCheck } from '@/designSystem/Icon/Internal/Icons/IconShieldCheck';
import { IconSparkles } from '@/designSystem/Icon/Internal/Icons/IconSparkles';
import { IconSparklesOutlined } from '@/designSystem/Icon/Internal/Icons/IconSparklesOutlined';
import { IconSpotify } from '@/designSystem/Icon/Internal/Icons/IconSpotify';
import { IconStar } from '@/designSystem/Icon/Internal/Icons/IconStar';
import { IconSun } from '@/designSystem/Icon/Internal/Icons/IconSun';
import { IconSupport } from '@/designSystem/Icon/Internal/Icons/IconSupport';
import { IconTrash } from '@/designSystem/Icon/Internal/Icons/IconTrash';
import { IconUser } from '@/designSystem/Icon/Internal/Icons/IconUser';
import { IconUserCircle } from '@/designSystem/Icon/Internal/Icons/IconUserCircle';
import { IconUserGroup } from '@/designSystem/Icon/Internal/Icons/IconUserGroup';
import { IconUserGroupOutlined } from '@/designSystem/Icon/Internal/Icons/IconUserGroupOutlined';
import { IconUsers } from '@/designSystem/Icon/Internal/Icons/IconUsers';
import { IconVideoCamera } from '@/designSystem/Icon/Internal/Icons/IconVideoCamera';
import { IconViewList } from '@/designSystem/Icon/Internal/Icons/IconViewList';
import { IconVisa } from '@/designSystem/Icon/Internal/Icons/IconVisa';
import { IconX } from '@/designSystem/Icon/Internal/Icons/IconX';
import { IconXCircle } from '@/designSystem/Icon/Internal/Icons/IconXCircle';
import { IconYoutube } from '@/designSystem/Icon/Internal/Icons/IconYoutube';

//! Merci de conserver l'ordre alphabétique
const iconMapping = {
  adjustments: IconAdjustments,
  arrowDownward: IconArrowDownward,
  arrowLeft: IconArrowLeft,
  badgeCheck: IconBadgeCheck,
  barChart: IconBarChart,
  bell: IconBell,
  bookOpen: IconBookOpen,
  calendar: IconCalendar,
  calendarOutlined: IconCalendarOutlined,
  cancel: IconCancel,
  certified: IconCertified,
  certifiedOutlined: IconCertifiedOutlined,
  chartSquareBar: IconChartSquareBar,
  chat: IconChat,
  chatAlt: IconChatAlt,
  chatAlt2Outlined: IconChatAlt2Outlined,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  checkCircleOutlined: IconCheckCircleOutlined,
  chevronDoubleUp: IconChevronDoubleUp,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  circle: IconCircle,
  circleDotted: IconCircleDotted,
  clock: IconClock,
  collection: IconCollection,
  copy: IconCopy,
  creditCard: IconCreditCard,
  danger: IconDanger,
  database: IconDatabase,
  desktopComputer: IconDesktopComputer,
  document: IconDocument,
  dotsHorizontal: IconDotsHorizontal,
  download: IconDownload,
  emojiHappy: IconEmojiHappy,
  euro: IconEuro,
  eye: IconEye,
  eyeOff: IconEyeOff,
  facebook: IconFacebook,
  flag: IconFlag,
  folder: IconFolder,
  globe: IconGlobe,
  google: IconGoogle,
  info: IconInfo,
  instagram: IconInstagram,
  level: IconLevel,
  lightBulb: IconLightBulb,
  lightningBolt: IconLightningBolt,
  link: IconLink,
  live: IconLive,
  location: IconLocation,
  lockClosed: IconLockClosed,
  mail: IconMail,
  marianne: IconMarianne,
  mastercard: IconMasterCard,
  message: IconMessage,
  minus: IconMinus,
  minusCircle: IconMinusCircle,
  movie: IconMovie,
  newspaper: IconNewspaper,
  online: IconOnline,
  panel: IconPanel,
  paperAirplane: IconPaperAirplane,
  paperClip: IconPaperClip,
  pencil: IconPencil,
  phone: IconPhone,
  play: IconPlay,
  plus: IconPlus,
  plusCircle: IconPlusCircle,
  presentationChartLine: IconPresentationChartLine,
  profile: IconProfile,
  receiptTax: IconReceiptTax,
  refresh: IconRefresh,
  reply: IconReply,
  setting: IconSetting,
  sherpaGreen: IconSherpaGreen,
  sherpaWhite: IconSherpaWhite,
  shieldCheck: IconShieldCheck,
  sparkles: IconSparkles,
  sparklesOutlined: IconSparklesOutlined,
  spotify: IconSpotify,
  star: IconStar,
  sun: IconSun,
  support: IconSupport,
  trash: IconTrash,
  user: IconUser,
  userCircle: IconUserCircle,
  userGroup: IconUserGroup,
  userGroupOutlined: IconUserGroupOutlined,
  users: IconUsers,
  videoCamera: IconVideoCamera,
  viewList: IconViewList,
  visa: IconVisa,
  x: IconX,
  xCircle: IconXCircle,
  youtube: IconYoutube,
} as const;

export default iconMapping;
